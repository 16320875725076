<template>
    <div class="flex flex-col justify-end h-screen  overflow-scroll">
        <div v-for="chat in chats" :key="chat._id" class="flex flex-col p-6 w-1/2" :class="chat.to == user_id ? 'self-end items-end' : 'self-start items-start'">
            <div class="text-left text-white bg-primary rounded-3xl p-4" :class="chat.to == user_id ? 'rounded-br-none' : 'rounded-bl-none'">
                {{chat.content}}
            </div>
            <p class="text-14 text-gray-500 mt-2 ">06:66 PM . Terkirim</p>
        </div>
        <div v-if="chats" class="flex justify-between bg-white rounded-t-2xl m-4">
            <input @keyup.enter="sendMessage" v-model="message" type="text" placeholder="Tulis pesanmu di sini..." class="text-16 p-7 w-full outline-none">
            <div class="flex items-center justify-center">
                <!-- <img src="../../public/assets/img/icons/icon_add.png" alt="icon_add" class="mr-5 h-1/3 cursor-pointer"> -->
                <img @click="sendMessage" src="../../public/assets/img/icons/icon_send.png" alt="icon_send" class="mr-5 h-1/3 cursor-pointer">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['teacher_id'],
    data() {
        return {
            // teacher_id: 'a07ea35b-1595-452e-b06d-a92fbc0a0a96',
            user_id: this.$route.params.mentor_id,
            chats: [],
            message: '',
        }
    },
    watch: {
        teacher_id() {
            this.getMentorChatHistory()
        }
    },
    methods: {
        getMentorChatHistory() {
            this.axios.get(`chat/user?user_id=${this.user_id}`).then(response => {
                this.chats = response.data.data
                console.log(this.chats);
            }).catch(error => {
                console.log(error.response);
            })
        },
        sendMessage() {
            const body = {
                user_id: this.user_id,
                type: 'text',
                content: this.message
            }
            this.axios.post('chat/send-message', body).then(response => {
                this.getMentorChatHistory()
                this.message = ''
            }).catch(error => {
                console.log(error.response);
            })
        }
    },
    mounted() {
        this.getMentorChatHistory()
    }
}
</script>

<style>

</style>